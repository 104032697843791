// @flow

import React, { PureComponent } from 'react';
import { ReadLegalQuery } from 'shared_data/providers/queries/ReadLegal';

type Props = { children: Function };

type State = { openedModal: ?string };

/**
 * LegalModal
 */
export class LegalModal extends PureComponent<Props, State> {
  static defaultProps: Object;

  /**
   * constructor
   * @param {Props} props
   */
  constructor(props: Props) {
    super(props);
    this.state = { openedModal: null };
    this.bindMethods();
  }

  /**
   * toggleModal
   *
   * @param {string} modalName
   * @returns {Function} Hanler function for open/close modal
   */
  toggleModal: Function;

  toggleModal(modalName: string): Function {
    return (e: Event): void => {
      e.preventDefault();

      this.setState((prevState) => ({
        openedModal: prevState.openedModal !== modalName ? modalName : null,
      }));
    };
  }

  /**
   * bindMethods
   */
  bindMethods() {
    this.toggleModal = this.toggleModal.bind(this);
  }

  /**
   * render
   */
  render() {
    const { children } = this.props;
    const { openedModal } = this.state;

    return (
      <React.Fragment>
        {openedModal && (
          <ReadLegalQuery type={openedModal} onClose={this.toggleModal(openedModal)} />
        )}
        {children({ toggleModal: this.toggleModal })}
      </React.Fragment>
    );
  }
}
