// @flow

import React from 'react';
import type { Node } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button } from '@riseart/common';
import { TextSmall } from '@riseart/layout';
import { legal as ENUM_LEGAL } from 'Enum';
import { LegalModal } from 'shared_components/forms/common/LegalModal';

type Props = {
  translationKey?: string,
  translationValues?: Object,
};

/**
 * TermsSection
 *
 * @param {Props} props
 */

/**
 * defaultProps
 */
export const TermsSection = ({
  translationKey = 'forms.common.confirmLegal',
  translationValues = {},
}: Props): Node => (
  <LegalModal>
    {({ toggleModal }) => (
      <TextSmall>
        <FormattedMessage
          id={translationKey}
          values={{
            terms: (
              <Button type="link" onClick={toggleModal(ENUM_LEGAL.type.LEGAL_TYPE_TERMS)}>
                <FormattedMessage id="forms.common.terms_and_conditions" />
              </Button>
            ),
            policy: (
              <Button type="link" onClick={toggleModal(ENUM_LEGAL.type.LEGAL_TYPE_PRIVACY)}>
                <FormattedMessage id="forms.common.privacy_policy" />
              </Button>
            ),
            ...translationValues,
          }}
        />
      </TextSmall>
    )}
  </LegalModal>
);
